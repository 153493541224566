import { Slot } from '@radix-ui/react-slot';
import { Link } from 'react-router';
import { cva } from 'class-variance-authority';
import LinkifyReact from 'linkify-react';
import type { IntermediateRepresentation } from 'linkifyjs';
import type React from 'react';
import { cn } from '~/utils/cn';

export const textVariants = cva('font-sans', {
  variants: {
    size: {
      xl: 'text-xl leading-[26px] tracking-[0.2px]',
      l: 'text-lg leading-[23.4px] tracking-[0.18px]',
      m: 'text-base leading-[20.8px] tracking-[0.16px]',
      s: 'text-sm leading-[18.4px] tracking-[0.14px]',
      xs: 'text-xs leading-[15.6px] tracking-[0.12px]',
      paragraph: 'text-base leading-6 tracking-[0.16px]',
      'paragraph-expanded': 'text-base leading-[28.8px] tracking-[0.16px]',
    },
  },
});

export const headingVariants = cva('text-balance font-serif font-medium', {
  variants: {
    size: {
      xxl: 'text-[56px] leading-[61.6px] tracking-[-0.56px]',
      xl: 'text-[44px] leading-[48.4px] tracking-[-0.44px]',
      l: 'text-4xl leading-[39.6px] tracking-[-0.36px]',
      m: 'text-[27px] leading-[32.4px]',
      s: 'text-2xl leading-[28.8px]',
      xs: 'text-[19px] leading-[24.7px]',
    },
  },
});

export function HeadingXXL({
  asChild,
  className,
  ...props
}: React.ComponentProps<'h1'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'h1';

  return <Comp {...props} className={cn(headingVariants({ size: 'xxl' }), className)} />;
}

export function HeadingXL({
  asChild,
  className,
  ...props
}: React.ComponentProps<'h2'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'h2';

  return <Comp {...props} className={cn(headingVariants({ size: 'xl' }), className)} />;
}

export function HeadingL({
  asChild,
  className,
  ...props
}: React.ComponentProps<'h3'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'h3';

  return <Comp {...props} className={cn(headingVariants({ size: 'l' }), className)} />;
}

export function HeadingM({
  asChild,
  className,
  ...props
}: React.ComponentProps<'h4'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'h4';

  return <Comp {...props} className={cn(headingVariants({ size: 'm' }), className)} />;
}

export function HeadingS({
  asChild,
  className,
  ...props
}: React.ComponentProps<'h5'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'h5';

  return <Comp {...props} className={cn(headingVariants({ size: 's' }), className)} />;
}

export function HeadingXS({
  asChild,
  className,
  ...props
}: React.ComponentProps<'h6'> & { asChild?: boolean }) {
  const Comp = asChild ? Slot : 'h6';

  return <Comp {...props} className={cn(headingVariants({ size: 'xs' }), className)} />;
}

export function H1(props: React.ComponentProps<'h1'>) {
  return <h1 {...props} className={headingVariants({ size: 'xxl', className: props.className })} />;
}

export function H2(props: React.ComponentProps<'h2'>) {
  return <h2 {...props} className={headingVariants({ size: 'xl', className: props.className })} />;
}

export function H3(props: React.ComponentProps<'h3'>) {
  return <h3 {...props} className={headingVariants({ size: 'l', className: props.className })} />;
}

export function H4(props: React.ComponentProps<'h4'>) {
  return (
    <h4
      {...props}
      className={headingVariants({
        size: 'm',
        className: props.className,
      })}
    />
  );
}

export function H5(props: React.ComponentProps<'h5'>) {
  return (
    <h5
      {...props}
      className={headingVariants({
        size: 's',
        className: props.className,
      })}
    />
  );
}

const renderLink = ({ attributes, content }: IntermediateRepresentation) => {
  const { href, ...props } = attributes;
  return (
    <Link
      to={href}
      prefetch={href.startsWith('/') ? 'intent' : undefined}
      {...props}
      className={cn(props.className, 'break-all underline underline-offset-2 hover:no-underline')}
    >
      {content}
    </Link>
  );
};

export const LINKIFY_OPTIONS = {
  nl2br: true,
  render: renderLink,
  target: { url: '_blank' },
};

export function Linkify(props: React.ComponentProps<typeof LinkifyReact> & { className?: string }) {
  return (
    <LinkifyReact
      as="p"
      options={LINKIFY_OPTIONS}
      {...props}
      className={cn(
        'break-before-page',
        textVariants({ size: 'paragraph-expanded' }),
        props.className,
      )}
    />
  );
}

export const bodyVariants = cva('font-sans', {
  variants: {
    size: {
      lg: 'text-lg md:text-[22px] md:leading-[33px]',
      DEFAULT: 'text-base md:text-xl md:leading-[30px]',
      sm: 'text-sm md:text-base',
    },
  },
});
